import { Fragment, h } from 'preact';
import { SpinnerWrapper, AnimatedLoader } from './styled';

const SpinnerParticles = () => (
	<Fragment>
		{[...Array(8)].map((x, index) => (
			<div key={index} />
		))}
	</Fragment>
);

function Spinner() {
	return (
		<SpinnerWrapper>
			<AnimatedLoader>
				<SpinnerParticles />
			</AnimatedLoader>
		</SpinnerWrapper>
	);
}
function AnimatedSpinner() {
	return (
		<AnimatedLoader>
			<SpinnerParticles />
		</AnimatedLoader>
	);
}

export { Spinner, AnimatedSpinner };
