import { h } from 'preact';
import { useCallback, useEffect, useRef, useState } from 'preact/hooks';
import TapperApiClient from '../../../shared/api/TapperApiClient';
import { Button, Container, Paragraph, Title } from '../../styled';
import Error from '../Error';

export default function EmailVerification({
	tapperApiClient,
	userId,
	emailVerificationUrl,
	onComplete,
}: {
	tapperApiClient: TapperApiClient;
	userId: string;
	emailVerificationUrl: string;
	onComplete: () => void;
}) {
	const [success, setSuccess] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);
	const [ssoWindow, setSsoWindow] = useState<Window>(null);
	const _componentMounted = useRef<boolean>(true);

	const initVerification = useCallback(() => {
		const windowWidth = 500;
		const windowHeight = 500;
		const windowScreenX = window.screen.width / 2 - windowWidth / 2;
		const windowScreenY = window.screen.height / 2 - windowHeight / 2;
		const popup = window.open(
			emailVerificationUrl,
			'',
			`width=${windowWidth},height=${windowHeight},popup=yes,screenX=${windowScreenX},screenY=${windowScreenY}`
		);

		setSsoWindow(popup);
	}, [emailVerificationUrl]);

	useEffect(() => {
		if (!ssoWindow) {
			return;
		}

		let interval = null;
		let retries = 60;

		interval = setInterval(() => {
			if (!_componentMounted.current) {
				clearInterval(interval);
				return;
			}

			if (ssoWindow?.closed) {
				clearInterval(interval);
				setSsoWindow(null);
				return;
			}

			if (retries > 0) {
				getUserData();
				retries--;
			} else {
				ssoWindow?.close();
			}
		}, 5000);

		async function getUserData() {
			tapperApiClient
				.getUserInfo(userId)
				.then(data => {
					if (data.is_email_verified) {
						setSuccess(true);
					}
				})
				.catch(() => setError(true));
		}

		return () => {
			_componentMounted.current = false;
		};
	}, [ssoWindow, tapperApiClient, userId]);

	useEffect(() => {
		if (success) {
			ssoWindow?.close();
			onComplete();
		}
	}, [success, ssoWindow, onComplete]);

	return (
		<Container data-step="email">
			<Title style={{ marginBottom: '20px' }}>Verify Your Email Address</Title>

			{error ? (
				<Error />
			) : (
				<Paragraph>Let's first make sure we can communicate with you.</Paragraph>
			)}
			{success ? (
				<p>
					<b>Success!</b>
					<br />
					Thank you for verifying your email!
				</p>
			) : ssoWindow ? (
				<Paragraph>Please check your inbox for a verification email.</Paragraph>
			) : (
				<Button onClick={initVerification}>Verify my email</Button>
			)}
		</Container>
	);
}
