/*
	TODOs:

	Most of this code comes from Contributions Menu
	* `decodeStateToJson` is meant as a validating and security check
		* Not using it so that could be an issue down the line. Resolve it later.
*/

import { useState, useEffect } from 'preact/hooks';
// import { decodeStateToJson } from '../../shared/oauth2Client/helpers';
import * as qs from 'query-string';
import TapperApiClient from '../api/TapperApiClient';
import jwtDecode from 'jwt-decode';

interface QueryParams {
	state: string;
	code: string;
}

export function useLogin({
	requireLogin,
	oauth2Client,
	tapperApiClient,
}: {
	requireLogin: boolean;
	oauth2Client: any;
	tapperApiClient: TapperApiClient;
}) {
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const { code, state } = qs.parse(window.location.search) as unknown as QueryParams;
	const [userId, setUserId] = useState<string>();
	const [accessToken, setAccessToken] = useState<string>();

	useEffect(() => {
		if (!accessToken) {
			return;
		}
		const { sub }: { sub: string } = jwtDecode(accessToken);
		setUserId(sub);
	}, [accessToken]);

	useEffect(() => {
		if (requireLogin) {
			init();
		}
	}, [requireLogin]);

	return { isLoggedIn, hasValidToken, login, userId };

	async function init() {
		const hasToken = await hasValidToken();
		if (!hasToken) login();
	}

	async function hasValidToken() {
		let authorized = await authorize();

		if (!authorized) {
			authorized = await tryRefreshToken();
		}

		return authorized;
	}

	async function authorize() {
		if (code && state) {
			try {
				// const { client_id } =
				// 	decodeStateToJson<{ redirect_uri: string; client_id: string }>(state);
				const payload = await oauth2Client.getToken({ state, code });
				tapperApiClient.setAuthorizationHeader(`Bearer ${payload.access_token}`);
				setIsLoggedIn(true);
				setAccessToken(payload.access_token);
				return true;
			} catch (error) {
				sessionStorage.clear();
				return false;
			} finally {
				window.history.replaceState({}, document.title, window.location.origin);
			}
		}
		return false;
	}

	async function tryRefreshToken() {
		if (oauth2Client.hasRefreshToken()) {
			try {
				const payload = await oauth2Client.refreshToken();
				tapperApiClient.setAuthorizationHeader(`Bearer ${payload.access_token}`);
				setIsLoggedIn(true);
				setAccessToken(payload.access_token);
				return true;
			} catch (error) {
				// don't do anything special except clearing sessionStorage because
				// it might have invalid refresh token if there are 2 duplicate tabs
				sessionStorage.clear();
				return false;
			}
		}
		return false;
	}

	async function login() {
		const authorizationUri = await oauth2Client.getAuthorizationUri();

		window.location.href = authorizationUri;
		return;
	}
}

export default useLogin;
