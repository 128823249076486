import { initStripe, StripeCheckoutProvider } from './stripe';
import CheckoutComponent from './component';
import { GuestCheckoutComponentProps, GuestCheckoutRenderOptions } from './types';
import { StripeDidNotLoadError } from './errors/StripeDidNotLoadError';
import { SingleUseTokenMissingError } from './errors/SingleUseTokenMissingError';
import BaseComponent from '../shared/BaseComponent';
import getEnv from '../getEnv';
import { getOAuth2Client } from '../shared/oauth2Client';
import TapperApiClient from '../shared/api/TapperApiClient';
import { TapperEnvironment } from '../shared/api/types';

export class GuestCheckout extends BaseComponent<GuestCheckoutComponentProps> {
	#stripe: stripe.Stripe;
	stripeTestMode: boolean;

	constructor({
		env,
		stripeTestMode,
	}: {
		env?: TapperEnvironment;
		stripeTestMode?: boolean;
	}) {
		super({ env });
		this.stripeTestMode = stripeTestMode;
	}

	async load() {
		this.#stripe = await initStripe(
			getEnv(this.tapperEnvironment, this.stripeTestMode).stripePublishableKey
		);
	}

	render(selector: string, options: GuestCheckoutRenderOptions) {
		if (!this.#stripe) {
			throw new StripeDidNotLoadError();
		}

		if (!options.singleUseToken) {
			throw new SingleUseTokenMissingError();
		}

		if (options.recurringPaymentOptions && !options.requireLogin) {
			throw new Error('Recurring contributions requires login.');
		}

		if (options.allowGuestRegistration && options.requireLogin) {
			throw new Error(
				'Requiring log in and allowing guest registration cannot both be true.'
			);
		}

		super.renderComponent({
			selector,
			Component: CheckoutComponent,
			componentProps: {
				cardOptions: options.cardOptions,
				checkoutProvider: new StripeCheckoutProvider({
					stripe: this.#stripe,
					singleUseToken: options.singleUseToken,
					tapperEnvironment: this.tapperEnvironment,
				}),
				onPaymentFail: options.onPaymentFail,
				onPaymentSuccess: options.onPaymentSuccess,
				paymentRequestButtonStyle: options.paymentRequestButtonStyle,
				paymentRequestOptions: options.paymentRequestOptions,
				showThankYouWidget: options.showThankYouWidget,
				requireLogin: options.requireLogin,
				clientId: options.clientId,
				redirectUri: options.redirectUri,
				oauth2Client: getOAuth2Client({
					clientId: options.clientId,
					redirectUri: options.redirectUri,
					baseURL: getEnv(this.tapperEnvironment).oauth2Url,
				}),
				allowGuestRegistration: options.allowGuestRegistration,
				tapperApiClient: new TapperApiClient({
					baseURL: getEnv(this.tapperEnvironment).tapperApiUrl,
				}),
				tapperEnvironment: this.tapperEnvironment,
				recurringPaymentOptions: options.recurringPaymentOptions,
				reCAPTCHAKey: getEnv(this.tapperEnvironment).reCAPTCHAKey,
			},
		});
	}

	destroy() {
		super.destroyComponent();
	}
}
