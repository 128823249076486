import { TapperEnvironment } from './shared/api/types';

type TapperApiUrl = `https://tapi.${'sbx.' | 'stg.' | ''}laterpay.net`;
type ContributeToUrl = `https://signon.${'sbx.' | 'stg.' | ''}${
	| 'contribute.to'
	| 'supertab.co'}`;
type Oauth2Url = `https://auth.${'sbx.' | 'stg.' | ''}laterpay.net/oauth2`;
type EmailVerificationUrl = `https://signon.${'sbx.' | 'stg.' | ''}${
	| 'contribute.to'
	| 'supertab.co'}/oauth2/email_verification`;

export interface Config {
	stripePublishableKey: string;
	tapperApiUrl: TapperApiUrl;
	contributeToUrl: ContributeToUrl;
	oauth2Url: Oauth2Url;
	emailVerificationUrl: EmailVerificationUrl;
	reCAPTCHAKey: string;
}

export default (
	environment: TapperEnvironment = 'prod',
	stripeTestMode?: boolean
): Config => {
	const stripeTestKey = 'pk_test_01AMnwndAdxYH7uWf0NzObg2';
	const stripeLiveKey = 'pk_live_ELR1bZxfAA8FPPfIZJsGwAq1';

	if (environment === 'sbx') {
		return {
			stripePublishableKey: stripeTestKey,
			tapperApiUrl: 'https://tapi.sbx.laterpay.net',
			contributeToUrl: 'https://signon.sbx.supertab.co',
			oauth2Url: 'https://auth.sbx.laterpay.net/oauth2',
			emailVerificationUrl: 'https://signon.sbx.supertab.co/oauth2/email_verification',
			reCAPTCHAKey: process.env.RECAPTCHA_KEY,
		};
	} else if (environment === 'stg') {
		return {
			stripePublishableKey: stripeTestKey,
			tapperApiUrl: 'https://tapi.stg.laterpay.net',
			contributeToUrl: 'https://signon.stg.supertab.co',
			oauth2Url: 'https://auth.stg.laterpay.net/oauth2',
			emailVerificationUrl: 'https://signon.stg.supertab.co/oauth2/email_verification',
			reCAPTCHAKey: process.env.RECAPTCHA_KEY,
		};
	} else {
		return {
			stripePublishableKey: stripeTestMode ? stripeTestKey : stripeLiveKey,
			tapperApiUrl: 'https://tapi.laterpay.net',
			contributeToUrl: 'https://signon.supertab.co',
			oauth2Url: 'https://auth.laterpay.net/oauth2',
			emailVerificationUrl: 'https://signon.supertab.co/oauth2/email_verification',
			reCAPTCHAKey: process.env.RECAPTCHA_KEY,
		};
	}
};
