/*
	TODOs:

	// * Use a different function to make payments if user is logged in
		* Not yet required. We can just fake it for now.
	* The way I'm handling guest_email seems bad so address it later.
	* Maybe create a useRecurringContribution hook
*/

import { h, createRef } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import ReCAPTCHA from 'react-google-recaptcha';
import Divider from '../shared/components/Divider';

import useCreditCardElement from './hooks/useCreditCardElement';
import usePaymentRequestButton from './hooks/usePaymentRequestButton';
import useLogin from '../shared/hooks/useLogin';
import useEmailField from '../shared/hooks/useEmailField';

import { GuestEmailValidationError } from './errors/GuestEmailValidationError';

import { GuestCheckoutComponentProps, PaymentIntent } from './types';
import { GuestCheckoutPaymentIntent } from './stripe/types';

import Button from '../shared/components/Button';
import Input from '../shared/components/Input';

import * as S from './styled';
import * as RecurringStyles from './components/RecurringContributions/styled';

import ThankYouWidget from './components/ThankYouWidget';
import GuestRegistrationWrapper from './components/GuestRegistration';
import RecurringContributions from './components/RecurringContributions';

export default function App({
	allowGuestRegistration,
	cardOptions,
	checkoutProvider,
	clientId,
	oauth2Client,
	onPaymentFail,
	onPaymentSuccess,
	paymentRequestButtonStyle,
	paymentRequestOptions,
	recurringPaymentOptions,
	redirectUri,
	requireLogin,
	showThankYouWidget,
	tapperApiClient,
	tapperEnvironment,
	reCAPTCHAKey,
}: GuestCheckoutComponentProps) {
	const recaptchaRef = createRef();
	const [tabData, setTabData] = useState(null);
	const [paymentMethod, setPaymentMethod] = useState(null);
	const [visibleForm, setVisibleForm] = useState('payment-request-button');
	const [processingPayment, setProcessingPayment] = useState(false);
	const [paymentComplete, setPaymentComplete] = useState<Boolean>(false);

	const paymentIntentRef = useRef<GuestCheckoutPaymentIntent | any>();

	const { emailRef, emailError, validateEmailField, setEmailError } = useEmailField();
	const { cardError, cardRef, setCardError } = useCreditCardElement(
		checkoutProvider,
		cardOptions
	);
	const { paymentRequestButtonRef, loaded } = usePaymentRequestButton(checkoutProvider, {
		paymentRequestOptions,
		paymentRequestButtonStyle,
		onPaymentRequestPaymentMethodEvent: handlePaymentRequestPaymentMethodEvent,
	});

	const { isLoggedIn, login, hasValidToken } = useLogin({
		/*
			TODO: Instead of passing a requireLogin option, we'll
			probably check for a new option like `mode: 'checkout'`
			or `mode: 'recurring-contribution'`
		*/
		requireLogin,

		oauth2Client,
		tapperApiClient,
	});

	const disabled =
		(!isLoggedIn && !emailRef?.current?.value) ||
		(!isLoggedIn && !!emailError) ||
		!cardRef?.current ||
		!!cardError;

	useEffect(() => {
		if (isLoggedIn) {
			if (recurringPaymentOptions && !tabData) {
				prepare();
			} else {
				setVisibleForm('credit-card');
			}
		}
		async function prepare() {
			const merchantId = await tapperApiClient.getMerchantId();
			const formData = {
				...recurringPaymentOptions,
				merchant_id: merchantId,
			};
			try {
				const tabData: any = await tapperApiClient.createRecurringContribution(formData);
				const pmData = await tapperApiClient.getDefaultPaymentMethod();
				setTabData(tabData);
				setPaymentMethod(pmData);
			} catch (error) {
				setPaymentMethod({ card: null, type: null });
			}
		}
	}, [isLoggedIn, tabData, recurringPaymentOptions, tapperApiClient]);

	useEffect(() => {
		if (allowGuestRegistration && !isLoggedIn) {
			hasValidToken();
		}
	}, [allowGuestRegistration]);

	if (paymentComplete) {
		return (
			<div>
				<ThankYouWidget />
				{allowGuestRegistration && !isLoggedIn && (
					<GuestRegistrationWrapper
						email={emailRef.current?.value}
						clientId={clientId}
						tapperEnvironment={tapperEnvironment}
						redirectBack={redirectUri}
					/>
				)}
			</div>
		);
	}

	if (recurringPaymentOptions) {
		return (
			<S.FlexColContainer>
				<S.PaymentRequestButtonContainer>
					<S.PaymentRequestButton
						ref={paymentRequestButtonRef}
						id="cto_payment-request-button"
						style={{ height: paymentRequestButtonStyle?.height }}
					/>
					{loaded && <Divider>Or pay with card</Divider>}
				</S.PaymentRequestButtonContainer>
				<RecurringContributions
					error={cardError}
					formState={visibleForm}
					onMakeRecurringPayment={handlePrepareRecurringPayment}
					paymentMethod={paymentMethod}
					processingPayment={processingPayment}
				/>
			</S.FlexColContainer>
		);
	}

	return (
		<S.Container formType={visibleForm}>
			{!requireLogin && allowGuestRegistration && !isLoggedIn && (
				<S.SignInPrompt>
					<a href="#" onClick={login}>
						Sign in
					</a>{' '}
					for a faster checkout experience, or you can continue as a guest. You will be
					able to create an account later.
				</S.SignInPrompt>
			)}
			<S.PaymentRequestButtonContainer>
				<S.PaymentRequestButton
					ref={paymentRequestButtonRef}
					id="cto_payment-request-button"
					style={{ height: paymentRequestButtonStyle?.height }}
				/>
				{loaded && <Divider>Or pay with card</Divider>}
			</S.PaymentRequestButtonContainer>
			{!requireLogin && !isLoggedIn && (
				<RecurringStyles.InputContainer>
					<RecurringStyles.Label>Email</RecurringStyles.Label>
					<Input error={emailError} type="email" ref={emailRef} />
				</RecurringStyles.InputContainer>
			)}
			<S.FlexColContainer>
				<RecurringStyles.InputContainer>
					<RecurringStyles.Label>Card Information</RecurringStyles.Label>
					<S.CardInput data-error-message={cardError} id="cto_stripe-credit-card" />
				</RecurringStyles.InputContainer>
				<Button
					onClick={!processingPayment && handleSubmit}
					disabled={disabled}
					data-testid="make-contribution"
				>
					{processingPayment ? 'Processing...' : 'Make Contribution'}
				</Button>
				<ReCAPTCHA
					isolated={true}
					ref={recaptchaRef}
					size="invisible"
					sitekey={reCAPTCHAKey}
					onChange={handleMakePayment}
				/>
			</S.FlexColContainer>
		</S.Container>
	);

	async function handleSubmit() {
		recaptchaRef.current?.execute();
	}

	async function getSetupIntent() {
		// Note: I am still referring to it as the "paymentIntentRef" for now
		if (!paymentIntentRef.current && tabData) {
			try {
				paymentIntentRef.current = await tapperApiClient.preparePaymentMethod();
			} catch (error) {
				setProcessingPayment(false);
				throw error;
			}
		}
		return paymentIntentRef.current;
	}

	async function handlePrepareRecurringPayment(state: string) {
		setProcessingPayment(true);
		if (tabData && state === 'saved') {
			handleConfirmRecurringPayment();
		} else {
			const setupIntent = await getSetupIntent();
			const confirmCardSetupResponse = await checkoutProvider.confirmCardSetup(
				setupIntent.clientSecret,
				{
					payment_method: {
						card: cardRef.current,
					},
				}
			);
			handleConfirmCardSetup(confirmCardSetupResponse);
		}
	}

	async function handleConfirmCardSetup(setupIntent: any) {
		if (setupIntent.error) {
			// Display result.error.message in your UI.
			setCardError(setupIntent.error.message);
			setProcessingPayment(false);
		} else {
			// The setup has succeeded. Display a success message and send
			// result.setupIntent.payment_method to your server to save the
			// card to a Customer
			handleConfirmRecurringPayment();
		}
	}

	async function handleConfirmRecurringPayment() {
		const response = await tapperApiClient.confirmPayment(tabData.id);
		setProcessingPayment(false);

		if (response.payment_outcome === 'succeeded') {
			handlePaymentSuccess({
				amount: tabData.purchases[0].price.amount,
				currency: tabData.purchases[0].price.currency,
			});
		} else if (response.payment_outcome === 'declined') {
			// TODO: Ask user to enter a new card
		} else if (response.payment_outcome === 'card_authentication_required') {
			// TODO: Needs 3D authentication
		}
	}

	async function getPaymentIntent() {
		if (!paymentIntentRef.current) {
			try {
				const guestEmail = emailRef?.current?.value; // can be undefined if we make user login
				const recaptchaToken = recaptchaRef?.current?.getValue(); // undefined if no recaptcha key

				paymentIntentRef.current = await checkoutProvider.getPaymentIntent({
					guestEmail,
					recaptchaToken,
				});
			} catch (error) {
				setProcessingPayment(false);
				if (error instanceof GuestEmailValidationError) {
					setEmailError(error.message);
				} else {
					setCardError(error.message);
				}
				throw error;
			}
		}
		return paymentIntentRef.current;
	}

	function handleCreditCardButtonClick() {
		const isValidEmail = validateEmailField();
		isValidEmail && setVisibleForm('credit-card');
	}

	async function handlePaymentRequestPaymentMethodEvent(ev) {
		const paymentIntent = await getPaymentIntent();

		// Confirm the PaymentIntent without handling potential next actions (yet).
		let confirmCardPaymentResult = await checkoutProvider.confirmCardPayment(
			paymentIntent.clientSecret,
			{ payment_method: ev.paymentMethod.id },
			{ handleActions: false }
		);

		if (confirmCardPaymentResult.error) {
			// Report to the browser that the payment failed, prompting it to
			// re-show the payment interface, or show an error message and close
			// the payment interface.
			ev.complete('fail');
		} else {
			// Report to the browser that the confirmation was successful, prompting
			// it to close the browser payment method collection interface.
			ev.complete('success');

			// Check if the PaymentIntent requires any actions and if so let Stripe.js
			// handle the flow.
			const paymentIntentRequiresAction =
				confirmCardPaymentResult.paymentIntent.status === 'requires_action';
			if (paymentIntentRequiresAction) {
				// Let Stripe.js handle the rest of the payment flow.
				confirmCardPaymentResult = await checkoutProvider.confirmCardPayment(
					paymentIntent.clientSecret
				);
			}
		}

		handleConfirmCardPayment(confirmCardPaymentResult);
		setProcessingPayment(false);
	}

	async function handleMakePayment() {
		setProcessingPayment(true);
		const paymentIntent = await getPaymentIntent();
		const confirmCardPaymentResponse = await checkoutProvider.confirmCardPayment(
			paymentIntent.clientSecret,
			{
				payment_method: { card: cardRef.current },
			}
		);
		setProcessingPayment(false);

		handleConfirmCardPayment(confirmCardPaymentResponse);
	}

	function handlePaymentSuccess(paymentIntentPayload: PaymentIntent) {
		showThankYouWidget && setPaymentComplete(true);
		onPaymentSuccess(paymentIntentPayload);
	}

	function handlePaymentFail(paymentIntentPayload: PaymentIntent, error: stripe.Error) {
		onPaymentFail(paymentIntentPayload, error);
	}

	async function handleConfirmCardPayment({
		paymentIntent,
		error,
	}: stripe.PaymentIntentResponse) {
		const paymentIntentPayload = {
			...paymentIntent,
			...(emailRef?.current?.value && { guest_email: emailRef.current.value }),
		};

		if (error) {
			// There's was an error with the confirm card payment.
			setCardError(error.message);
		} else if (paymentIntentPayload.status === 'succeeded') {
			// Success! Payment is confirmed.
			handlePaymentSuccess(paymentIntentPayload);
			// Payment intent has been used, and if we try to
			// use it again, we'll get an error
			paymentIntentRef.current = undefined;
		} else if (paymentIntentPayload.status === 'processing') {
			// Success! Payment is yet to be confirmed.
			// While for some payment methods (e.g., cards) processing can be quick,
			// other types of payment methods can take up to a few days to process.
			handlePaymentSuccess(paymentIntentPayload);
			// Payment intent has been used, and if we try to
			// use it again, we'll get an error
			paymentIntentRef.current = undefined;
		} else if (paymentIntentPayload.status === 'requires_payment_method') {
			// If the payment attempt fails (for example due to a decline),
			// the PaymentIntent’s status returns to requires_payment_method
			handlePaymentFail(paymentIntentPayload, error);
			setCardError(paymentIntentPayload.last_payment_error.message || 'Payment failed.');
		} else {
			// Payment failed for unknown reason.
			handlePaymentFail(paymentIntentPayload, error);
			setCardError('Payment failed.');
		}
	}
}
