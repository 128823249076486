import { Fragment, h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import TapperApiClient from '../../../shared/api/TapperApiClient';
import { MerchantData, FormStatus, ErrorObject } from '../../types';
import { supportedCountries } from '../../../utils';
import Success from '../Success';
import * as S from '../../styled';
import { defaultError } from '../../utils';

interface BankingInfoProps {
	merchant: MerchantData;
	tapperApiClient: TapperApiClient;
	onKYCSuccess: () => void;
}

export default function BankingInfo({
	merchant,
	tapperApiClient,
	onKYCSuccess,
}: BankingInfoProps) {
	const [formStatus, setFormStatus] = useState<FormStatus>('default');
	const [error, setError] = useState<ErrorObject>(defaultError);
	const [accountNumber, setAccountNumber] = useState<string>('');
	const [routingNumber, setRoutingNumber] = useState<string>('');
	const [sortNumber, setSortNumber] = useState<string>('');
	const [country, setCountry] = useState<string>(merchant.country);
	const bankingDetails = getCountryBankingDetails(country);

	useEffect(() => {
		setError(defaultError);

		if (country && accountNumber) {
			if (bankingDetails.fields.includes('routing_number') && !routingNumber) {
				setFormStatus('default');
			} else if (bankingDetails.fields.includes('sort_number') && !sortNumber) {
				setFormStatus('default');
			} else {
				setFormStatus('valid');
			}
		} else {
			setFormStatus('default');
		}
	}, [country, accountNumber, routingNumber, sortNumber]);

	useEffect(() => {
		error.message && setFormStatus('error');
	}, [error]);

	return (
		<div>
			{formStatus === 'submitted' ? (
				<Success onClick={onKYCSuccess} />
			) : (
				<Fragment>
					<S.Form onSubmit={handleSubmit}>
						<S.Title>Connect Your Bank</S.Title>
						{error.message && <S.ErrorMessage>{error.message}</S.ErrorMessage>}
						<S.SelectWrapperFlex>
							<S.InputGroup data-error={error.field === 'country'}>
								<S.InputLabel>Country</S.InputLabel>
								<S.FormInput
									type="text"
									readonly={true}
									disabled={true}
									value={supportedCountries[country]}
								/>
								{error.field === 'country' && (
									<S.ErrorMessage>{error.message}</S.ErrorMessage>
								)}
							</S.InputGroup>
							<S.InputGroup data-error={error.field === 'currency'}>
								<S.InputLabel>Currency</S.InputLabel>
								<S.FormInput
									type="text"
									readonly={true}
									disabled={true}
									value={bankingDetails.currency}
								/>
								{error.field === 'currency' && (
									<S.ErrorMessage>{error.message}</S.ErrorMessage>
								)}
							</S.InputGroup>
						</S.SelectWrapperFlex>
						{bankingDetails.fields.includes('routing_number') && (
							<S.InputGroup>
								<S.InputLabel>Routing Number</S.InputLabel>
								<S.FormInput
									onChange={e => setRoutingNumber((e.target as HTMLInputElement).value)}
									placeholder="Routing Number"
									required={true}
									type="text"
									value={routingNumber}
								/>
							</S.InputGroup>
						)}
						{bankingDetails.fields.includes('sort_number') && (
							<S.InputGroup>
								<S.InputLabel>Sort Number</S.InputLabel>
								<S.FormInput
									onChange={e => setSortNumber((e.target as HTMLInputElement).value)}
									placeholder="Sort Number"
									required={true}
									type="text"
									value={sortNumber}
								/>
							</S.InputGroup>
						)}
						<S.InputGroup data-error={error.field === 'account_number'}>
							<S.InputLabel>
								{bankingDetails.fields.length > 1 ? 'Account Number' : 'IBAN'}
							</S.InputLabel>
							<S.FormInput
								onChange={e => setAccountNumber((e.target as HTMLInputElement).value)}
								placeholder={bankingDetails.fields.length > 1 ? 'Account Number' : 'IBAN'}
								required={true}
								type="text"
								value={accountNumber}
							/>
							{error.field === 'account_number' && (
								<S.ErrorMessage>{error.message}</S.ErrorMessage>
							)}
						</S.InputGroup>
						<S.Button type="submit" disabled={formStatus !== 'valid'}>
							{formStatus === 'submitting' ? 'Please wait…' : 'Submit'}
						</S.Button>
						{error.message && (
							<S.ErrorMessage data-position="footer">{error.message}</S.ErrorMessage>
						)}
					</S.Form>
				</Fragment>
			)}
		</div>
	);

	function handleSubmit(e) {
		e.preventDefault();

		setFormStatus('submitting');

		const data = {
			id: merchant.id,
			country: country,
			currency: bankingDetails.currency,
			account_number: accountNumber.replaceAll(' ', ''),
			routing_number: routingNumber,
		};

		tapperApiClient
			.createBankAccount(data)
			.then(() => {
				setFormStatus('submitted');
				onKYCSuccess();
			})
			.catch(submissionError => {
				setError({
					message: submissionError.message,
					field: submissionError.field ?? '',
				});
			});
	}
}

const getCountryBankingDetails = (
	country: string
): {
	fields: string[];
	currency: string;
} => {
	let currency = 'USD';
	const fields = ['account_number'];

	switch (country) {
		case 'AT':
		case 'BE':
		case 'CY':
		case 'EE':
		case 'FI':
		case 'FR':
		case 'DE':
		case 'GR':
		case 'IE':
		case 'IT':
		case 'LV':
		case 'LT':
		case 'LU':
		case 'MT':
		case 'NL':
		case 'PT':
		case 'SK':
		case 'SI':
		case 'ES':
			currency = 'EUR';
			break;
		case 'BG':
			currency = 'BGN';
			break;
		case 'CZ':
			currency = 'CZK';
			break;
		case 'HU':
			currency = 'HUF';
			break;
		case 'NO':
			currency = 'NOK';
			break;
		case 'PL':
			currency = 'PLN';
			break;
		case 'RO':
			currency = 'RON';
			break;
		case 'SE':
			currency = 'SEK';
			break;
		case 'CH':
			currency = 'CHF';
			break;
		case 'GB':
			fields.push('sort_number');
			currency = 'GBP';
			break;
		case 'US':
			fields.push('routing_number');
			currency = 'USD';
			break;
	}

	return { fields, currency };
};
