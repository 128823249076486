/*
	TODOs:

	* Maybe we separate out some things
		* e.g. MerchantService, UserService, PaymentService, etc...

*/

import axios, { AxiosInstance } from 'axios';
import { FormType, MerchantData, UserData } from '../../KYC/types';
import {
	MakeContributionResponse,
	PurchaseItem,
	Tab,
	TapperPaymentIntent,
} from './types';

class TapperApiClient {
	axios: AxiosInstance;

	constructor({ baseURL }: { baseURL: string }) {
		this.axios = axios.create({ baseURL });
	}

	setAuthorizationHeader(header: string) {
		this.axios.defaults.headers.common['Authorization'] = header;
	}

	async makeContribution(payload: PurchaseItem) {
		try {
			const { data } = await this.axios.post<MakeContributionResponse>('/v1/purchase', {
				offering_id: payload.offeringId,
				payment_model: payload.paymentModel,
				price: payload.price,
				sales_model: payload.salesModel,
				summary: payload.summary,
			});

			return data.tab;
		} catch (error) {
			// Payment required
			if (error?.response?.status === 402) {
				return error.response.data.tab as Tab;
			}

			throw error;
		}
	}

	async getUserTab() {
		const { data } = await this.axios.get<Tab[]>('/v1/tabs');

		// Currently there is no way to get a full tab without knowing the tab id
		// other than this.
		// This is just a temporary solution agreed upon in Slack cross team channel.
		return data?.filter(tab => tab.currency === 'USD' && tab.status === 'full')?.[0];
	}

	async startTapperPaymentIntent(tabId: string) {
		const { data } = await this.axios.get<TapperPaymentIntent>(
			`/v1/payment/start/${tabId}`
		);
		return data;
	}

	async getMerchantId() {
		try {
			const { data } = await this.axios.get('/v1/auth/roles/me');

			if (!data[0]) {
				throw new Error(`Couldn't find merchant ID.`);
			}

			return data[0]?.resource_id;
		} catch (error) {
			throw error;
		}
	}

	async getMerchantInfo(merchantId: string) {
		try {
			if (!merchantId) {
				throw new Error(`Invalid merchant ID.`);
			}

			const { data } = await this.axios.get<MerchantData>(`/v1/merchants/${merchantId}`);

			return data;
		} catch (error) {
			throw error;
		}
	}

	async getUserInfo(userId: string) {
		try {
			if (!userId) {
				throw new Error('Invalid user ID.');
			}

			const { data } = await this.axios.get<UserData>(`/v1/identity/user/${userId}`);

			return data;
		} catch (error) {
			throw error;
		}
	}

	async updateMerchantInfo(merchantData, formType: FormType) {
		let error: any = new Error();

		try {
			if (!merchantData.country) {
				error.message = 'Country is required.';
				error.field = 'country';
				throw error;
			}

			if (formType === 'individual' || formType === 'company-of-one') {
				if (!merchantData.first_name) {
					error.message = 'First Name is required.';
					error.field = 'first_name';
					throw error;
				}

				if (!merchantData.last_name) {
					error.message = 'Last Name is required.';
					error.field = 'last_name';
					throw error;
				}

				if (!merchantData.dob) {
					error.message = 'Date of Birth is required.';
					error.field = 'dob';
					throw error;
				}
			}

			if (formType === 'company' || formType === 'company-of-one') {
				if (!merchantData.url) {
					error.message = 'URL is required.';
					error.field = 'url';
					throw error;
				}

				try {
					new URL(merchantData.url);
				} catch (e) {
					error.field = 'url';
					error.message = 'Please enter valid secure (https) URL.';
					throw error;
				}
			}

			if (formType === 'company') {
				if (!merchantData.company_name) {
					error.message = 'Company Name is required.';
					error.field = 'company_name';
					throw error;
				}

				if (!merchantData.company_tax_id) {
					error.message = 'Company Tax ID is required.';
					error.field = 'company_tax_id';
					throw error;
				}

				if (!merchantData.company_structure) {
					error.message = 'Company Structure is required.';
					error.field = 'company_structure';
					throw error;
				}
			}

			if (formType === 'company-of-one') {
				if (!merchantData.ssn_last_4) {
					error.message = 'Last 4 digits from SSN are required.';
					error.field = 'ssnLast4';
					throw error;
				}
			}

			try {
				const { data } = await this.axios.patch(
					`/v1/merchants/${merchantData.id}`,
					merchantData
				);

				return data;
			} catch (e) {
				const { data } = e.response;

				if (data.error?.code === 'validation_error') {
					error.message = data.error?.errors[0]?.message;
				} else {
					error.message = data.error?.message;
				}

				error.field = '';

				throw error;
			}
		} catch (e) {
			throw e;
		}
	}

	async getMerchantRequirements(merchantId: string) {
		try {
			if (!merchantId) {
				throw new Error(`Invalid merchant ID.`);
			}

			const { data } = await this.axios.get<MerchantData>(
				`/v1/merchants/${merchantId}/requirements`
			);

			return data;
		} catch (error) {
			throw error;
		}
	}

	async getMerchantMembers(merchantId: string) {
		try {
			if (!merchantId) {
				throw new Error(`Invalid merchant ID.`);
			}

			const { data } = await this.axios.get<unknown>(
				`/v1/merchants/${merchantId}/members`
			);

			return data;
		} catch (error) {
			throw error;
		}
	}

	async getBankInfo(merchantId: string) {
		try {
			if (!merchantId) {
				throw new Error(`Invalid merchant ID.`);
			}

			const { data } = await this.axios.get<MerchantData>(
				`/v1/merchants/${merchantId}/bank_account`
			);

			return data;
		} catch (error) {
			throw error;
		}
	}

	async createBankAccount({ id, ...merchantData }) {
		let error: any = new Error('');

		try {
			if (!merchantData.account_number) {
				error.message = 'Account Number is required.';
				error.field = 'account_number';
				throw error;
			}

			if (!merchantData.currency) {
				error.message = 'Currency is required.';
				error.field = 'currency';
				throw error;
			}

			if (!merchantData.country) {
				error.message = 'Country is required.';
				error.field = 'country';
				throw error;
			}

			try {
				const { data } = await this.axios.post(
					`/v1/merchants/${id}/bank_account`,
					merchantData
				);
				return data;
			} catch (e) {
				const { data } = e.response;

				if (data.error?.code === 'validation_error') {
					error.message = data.error?.errors[0]?.message;
				} else {
					error.message = data.error?.message;
				}

				error.field = '';

				throw error;
			}
		} catch (e) {
			throw e;
		}
	}

	async createRecurringContribution({
		billing_interval,
		merchant_id,
		price,
	}: {
		billing_interval: string;
		merchant_id: string;
		price: { amount: number; currency: string };
	}) {
		try {
			const { data } = await this.axios.post<unknown>(`/v1/purchase/recurring`, {
				billing_interval,
				merchant_id,
				price: {
					amount: price.amount,
					currency: price.currency,
				},
			});
			return data;
		} catch (error) {
			throw error;
		}
	}

	async getDefaultPaymentMethod() {
		try {
			const { data } = await this.axios.get<unknown>(`/v1/payment/methods/default`);
			return data;
		} catch (error) {
			throw error;
		}
	}

	async preparePaymentMethod() {
		try {
			const { data }: any = await this.axios.post<unknown>(`/v1/payment/methods/prepare`);
			return { clientSecret: data.client_secret };
		} catch (error) {
			throw error;
		}
	}

	async confirmPayment(tabId: string) {
		try {
			const { data }: any = await this.axios.post<unknown>(
				`/v1/payment/confirm/${tabId}`
			);
			return data;
		} catch (error) {
			throw error;
		}
	}
}

export default TapperApiClient;
