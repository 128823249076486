import { Fragment, h } from 'preact';
import { useEffect, useMemo, useRef, useState } from 'preact/hooks';
import TapperApiClient from '../../../shared/api/TapperApiClient';
import { supportedCountries } from '../../../utils';
import {
	MerchantData,
	FormStatus,
	BusinessType,
	FormType,
	CompanyStructure,
	ErrorObject,
	KYCSharedProps,
} from '../../types';
import Success from '../Success';

import * as S from '../../styled';
import {
	companyStructureOptions,
	countriesSupportingBusiness,
	defaultError,
} from '../../utils';

interface BasicInfoProps extends KYCSharedProps {
	merchant: Partial<MerchantData>;
	tapperApiClient: TapperApiClient;
}

const days = new Array(31).fill('');
const startYear = new Date().getFullYear() - 12;
const years = Array.from(new Array(100), (value, index) => startYear - index);

function pad(number: number): string {
	return number > 9 ? number.toString() : `0${number.toString()}`;
}

export default function BasicInfo({
	merchant = {},
	tapperApiClient,
	onKYCSuccess,
	onSuccessPageButtonClick,
	successPageButtonLabel,
	successPageInstructions,
	successPageTitle,
}: BasicInfoProps) {
	const [formStatus, setFormStatus] = useState<FormStatus>('default');
	const [formType, setFormType] = useState<FormType>('');
	const [error, setError] = useState<ErrorObject>(defaultError);

	// Form fields.
	const [firstName, setFirstName] = useState<string>(merchant.first_name);
	const [lastName, setLastName] = useState<string>(merchant.last_name);
	const [dobDay, setDobDay] = useState<number>(0);
	const [dobMonth, setDobMonth] = useState<number>(0);
	const [dobYear, setDobYear] = useState<number>(0);
	const [dob, setDob] = useState<string>('');
	const [country, setCountry] = useState<string>(merchant.country);
	const [businessType, setBusinessType] = useState<BusinessType>(merchant.business_type);
	const [companyName, setCompanyName] = useState<string>(merchant.company_name);
	const [companyTaxId, setCompanyTaxId] = useState<string>(merchant.company_tax_id);
	const [companyStructure, setCompanyStructure] = useState<CompanyStructure>(undefined);
	const [ssnLast4, setSsnLast4] = useState<number>(undefined);
	const [url, setUrl] = useState<string>('');
	const urlRef = useRef<HTMLInputElement>(null);

	const shouldShowUrl =
		(formType === 'company' || formType === 'company-of-one') && !!companyStructure;
	const shouldShowSsnLast4 = formType === 'company-of-one' && !!companyStructure;
	const shouldShowAccountTypeSelector = countriesSupportingBusiness.includes(country);

	const stripeAgreementLink = useMemo(() => {
		const link =
			country === 'US'
				? 'https://stripe.com/connect-account/legal/full'
				: 'https://stripe.com/connect-account/legal/recipient';
		const linkText =
			country === 'US'
				? 'Stripe Connected Account Agreement'
				: 'Stripe Recipient Agreement';
		return (
			<a href={link} target="_blank" rel="noreferrer">
				{linkText}
			</a>
		);
	}, [country]);

	useEffect(() => {
		if (dobDay && dobMonth && dobYear) {
			setDob(`${dobYear}-${pad(dobMonth)}-${pad(dobDay)}`);
		}
	}, [dobDay, dobMonth, dobYear]);

	useEffect(() => {
		setError(defaultError);

		switch (formType) {
			case 'individual':
				if (!firstName || !lastName || !dob || !country) {
					//setFormStatus('default');
					return;
				}
				break;

			case 'company':
				if (!companyName || !companyTaxId || !companyStructure || !country || !url) {
					//setFormStatus('default');
					return;
				}
				break;

			case 'company-of-one':
				if (!firstName || !lastName || !dob || !country || !ssnLast4 || !url) {
					//setFormStatus('default');
					return;
				}
				break;
		}

		setFormStatus('valid');
	}, [
		formType,
		firstName,
		lastName,
		dob,
		country,
		companyName,
		companyTaxId,
		companyStructure,
		url,
		ssnLast4,
	]);

	useEffect(() => {
		if (country) {
			switch (country) {
				case 'US':
					if (country !== merchant.country) {
						setBusinessType(undefined);
					} else {
						setBusinessType(merchant.business_type);
					}
					break;
				default:
					setBusinessType('individual');
					setFormType('individual');
					break;
			}
		}
	}, [country]);

	useEffect(() => {
		function resetCompanyFields() {
			setCompanyName('');
			setCompanyTaxId('');
		}

		function resetPersonalFields() {
			setFirstName('');
			setLastName('');
			setDob('');
		}

		function resetCompanyOfOneFields() {
			setSsnLast4(undefined);
		}

		if (formType === 'individual') {
			resetCompanyFields();
			resetCompanyOfOneFields();
			setCompanyStructure(undefined);
		} else if (formType === 'company') {
			resetPersonalFields();
			resetCompanyOfOneFields();
		} else {
			resetPersonalFields();
			resetCompanyFields();
		}
	}, [formType]);

	useEffect(() => {
		switch (companyStructure) {
			case 'sole_proprietorship':
			case 'single_member_llc':
				setFormType('company-of-one');
				return;

			case 'private_partnership':
			case 'private_corporation':
			case 'unincorporated_association':
			case 'public_partnership':
			case 'public_corporation':
				setFormType('company');
				return;
		}
	}, [companyStructure]);

	useEffect(() => {
		setFormType(businessType);
	}, [businessType]);

	useEffect(() => {
		error.message && setFormStatus('error');
	}, [error]);

	if (urlRef) {
		urlRef.current?.addEventListener('blur', (e: any) => {
			if (!/^(http(s?)):\/\//i.test(e.target.value)) {
				setUrl(`https://${e.target.value}`);
			}
		});
	}

	return (
		<Fragment>
			{formStatus === 'submitted' ? (
				<Success
					title={successPageTitle}
					instructions={successPageInstructions}
					buttonLabel={successPageButtonLabel}
					onClick={onSuccessPageButtonClick}
				/>
			) : (
				<S.Form onSubmit={handleSubmit}>
					<S.Title>Tell Us Who You Are</S.Title>

					{error.message && <S.ErrorMessage>{error.message}</S.ErrorMessage>}

					<S.InputGroup data-error={error.field === 'country'}>
						<S.InputLabel for="cto_kyc_country">Country</S.InputLabel>
						<S.SelectWrapper>
							<S.Select
								id="cto_kyc_country"
								onChange={e => setCountry(e.currentTarget.value)}
								required={true}
							>
								<option value="" selected disabled>
									Country
								</option>
								{Object.keys(supportedCountries).map((code, index) => (
									<option value={code} key={index} selected={country === code}>
										{supportedCountries[code]}
									</option>
								))}
							</S.Select>
						</S.SelectWrapper>
						{error.field === 'country' && (
							<S.ErrorMessage>{error.message}</S.ErrorMessage>
						)}
					</S.InputGroup>

					{shouldShowAccountTypeSelector && (
						<S.InputGroup>
							<S.InputLabel for="cto_kyc_business_type">Account Type</S.InputLabel>
							<S.SelectWrapper>
								<S.Select
									id="cto_kyc_business_type"
									onChange={e => setBusinessType(e.currentTarget.value)}
									required={true}
								>
									<option value="" selected={!businessType} disabled>
										Account Type
									</option>
									<option
										value="individual"
										key="business_type_individual"
										selected={businessType === 'individual'}
									>
										Individual
									</option>
									<option
										value="company"
										key="business_type_company"
										selected={businessType === 'company'}
									>
										Business
									</option>
								</S.Select>
							</S.SelectWrapper>
						</S.InputGroup>
					)}

					{(formType === 'company' || formType === 'company-of-one') && (
						<Fragment>
							<S.InputGroup data-error={error.field === 'company_structure'}>
								<S.InputLabel for="cto_kyc_company_structure">
									Company Structure
								</S.InputLabel>
								<S.SelectWrapper>
									<S.Select
										id="cto_kyc_company_structure"
										onChange={e => setCompanyStructure(e.currentTarget.value)}
										required={true}
									>
										<option value="" selected disabled>
											Company Structure
										</option>
										{Object.keys(companyStructureOptions).map(option => (
											<option
												value={option}
												key={option}
												selected={companyStructure === option}
											>
												{companyStructureOptions[option]}
											</option>
										))}
									</S.Select>
								</S.SelectWrapper>
								{error.field === 'company_structure' && (
									<S.ErrorMessage>{error.message}</S.ErrorMessage>
								)}
							</S.InputGroup>
						</Fragment>
					)}

					{(formType === 'individual' || formType === 'company-of-one') && (
						<Fragment>
							<S.InputGroup
								style={{ width: 'calc(50% - 6px)' }}
								data-error={error.field === 'first_name'}
							>
								<S.InputLabel for="cto_kyc_first_name">First Name</S.InputLabel>
								<S.FormInput
									type="text"
									id="cto_kyc_first_name"
									value={firstName}
									placeholder="First Name"
									onChange={e => setFirstName(e.currentTarget.value)}
									required={true}
								/>
								{error.field === 'first_name' && (
									<S.ErrorMessage>{error.message}</S.ErrorMessage>
								)}
							</S.InputGroup>
							<S.InputGroup
								style={{ width: 'calc(50% - 6px)' }}
								data-error={error.field === 'last_name'}
							>
								<S.InputLabel for="cto_kyc_last_name">Last Name</S.InputLabel>
								<S.FormInput
									type="text"
									id="cto_kyc_last_name"
									value={lastName}
									placeholder="Last Name"
									onChange={e => setLastName(e.currentTarget.value)}
									required={true}
								/>
								{error.field === 'last_name' && (
									<S.ErrorMessage>{error.message}</S.ErrorMessage>
								)}
							</S.InputGroup>
							<S.InputGroup data-error={error.field === 'dob'}>
								<S.InputLabel for="cto_kyc_birthdate_day">Birth Date</S.InputLabel>
								<S.SelectWrapperFlex>
									<S.SelectWrapper>
										<S.Select
											id="cto_kyc_birthdate_day"
											data-testid="cto_kyc_birthdate_day"
											onChange={e => setDobDay(e.currentTarget.value)}
										>
											<option value="" selected disabled>
												Day
											</option>
											{days.map((index, day) => (
												<option key={day + 1} value={day + 1}>
													{day + 1}
												</option>
											))}
										</S.Select>
									</S.SelectWrapper>
									<S.SelectWrapper>
										<S.Select
											data-testid="cto_kyc_birthdate_month"
											onChange={e => setDobMonth(e.currentTarget.value)}
										>
											<option value="" selected disabled>
												Month
											</option>
											<option value="1">January</option>
											<option value="2">February</option>
											<option value="3">March</option>
											<option value="4">April</option>
											<option value="5">May</option>
											<option value="6">June</option>
											<option value="7">July</option>
											<option value="8">August</option>
											<option value="9">September</option>
											<option value="10">October</option>
											<option value="11">November</option>
											<option value="12">December</option>
										</S.Select>
									</S.SelectWrapper>
									<S.SelectWrapper>
										<S.Select
											data-testid="cto_kyc_birthdate_year"
											onChange={e => setDobYear(e.currentTarget.value)}
										>
											<option value="" selected disabled>
												Year
											</option>
											{years.map(value => (
												<option key={value} value={value}>
													{value}
												</option>
											))}
										</S.Select>
									</S.SelectWrapper>
								</S.SelectWrapperFlex>
								{error.field === 'dob' && (
									<S.ErrorMessage>{error.message}</S.ErrorMessage>
								)}
							</S.InputGroup>
						</Fragment>
					)}

					{formType === 'company' && companyStructure && (
						<Fragment>
							<S.InputGroup
								style={{ width: 'calc(50% - 6px)' }}
								data-error={error.field === 'company_name'}
							>
								<S.InputLabel for="cto_kyc_company_name">Company Name</S.InputLabel>
								<S.FormInput
									type="text"
									id="cto_kyc_company_name"
									value={companyName}
									placeholder="Company Name"
									onChange={e => setCompanyName(e.currentTarget.value)}
									required={true}
								/>
								{error.field === 'company_name' && (
									<S.ErrorMessage>{error.message}</S.ErrorMessage>
								)}
							</S.InputGroup>
							<S.InputGroup
								style={{ width: 'calc(50% - 6px)' }}
								data-error={error.field === 'company_tax_id'}
							>
								<S.InputLabel for="cto_kyc_company_tax_id">Company Tax ID</S.InputLabel>
								<S.FormInput
									type="text"
									id="cto_kyc_company_tax_id"
									value={companyTaxId}
									placeholder="Company Tax ID"
									onChange={e => setCompanyTaxId(e.currentTarget.value)}
									required={true}
								/>
								{error.field === 'company_tax_id' && (
									<S.ErrorMessage>{error.message}</S.ErrorMessage>
								)}
							</S.InputGroup>
						</Fragment>
					)}

					{shouldShowUrl && (
						<S.InputGroup data-error={error.field === 'url'}>
							<S.InputLabel for="cto_kyc_url">URL</S.InputLabel>
							<S.FormInput
								type="text"
								id="cto_kyc_url"
								value={url}
								placeholder="https://"
								onChange={e => setUrl(e.currentTarget.value)}
								required={true}
								ref={urlRef}
							/>
							{error.field === 'url' && <S.ErrorMessage>{error.message}</S.ErrorMessage>}
						</S.InputGroup>
					)}

					{shouldShowSsnLast4 && (
						<S.InputGroup data-error={error.field === 'ssnLast4'}>
							<S.InputLabel for="cto_kyc_ssn_last_4">Last 4 digits from SSN</S.InputLabel>
							<S.FormInput
								type="text"
								id="cto_kyc_ssn_last_4"
								value={ssnLast4}
								minLength={4}
								maxLength={4}
								placeholder="Last 4 digits from SSN"
								onChange={e => setSsnLast4(e.currentTarget.value)}
								required={true}
							/>
							{error.field === 'ssnLast4' && (
								<S.ErrorMessage>{error.message}</S.ErrorMessage>
							)}
						</S.InputGroup>
					)}

					<S.Button type="submit" disabled={formStatus !== 'valid'}>
						{formStatus === 'submitting' ? 'Please wait…' : 'Submit'}
					</S.Button>

					{error.message && !error.field && (
						<S.ErrorMessage data-position="footer">{error.message}</S.ErrorMessage>
					)}

					<S.Paragraph>
						By submitting this form, I confirm that I have read and accept Contribute.to's{' '}
						<a
							href="https://contribute.to/assets/documents/CTO-Terms_of_Service.pdf"
							target="_blank"
							rel="noreferrer"
						>
							Terms & Conditions
						</a>{' '}
						and the {stripeAgreementLink}.
					</S.Paragraph>
				</S.Form>
			)}
		</Fragment>
	);

	async function handleSubmit(e) {
		e.preventDefault();

		setFormStatus('submitting');

		try {
			const data = {
				id: merchant.id,
				country,
				business_type: businessType,
				url: businessType === 'individual' ? window.location.origin : url,
				...(firstName && { first_name: firstName }),
				...(lastName && { last_name: lastName }),
				...(dob && { dob }),
				...(companyStructure && { company_structure: companyStructure }),
				...(companyName && { company_name: companyName }),
				...(companyTaxId && { company_tax_id: companyTaxId }),
				...(ssnLast4 && { ssn_last_4: ssnLast4 }),
			};

			await tapperApiClient.updateMerchantInfo(data, formType);
			setFormStatus('submitted');
			onKYCSuccess();
		} catch (e) {
			setError({ message: e.message, field: e.field ?? null });
		}
	}
}
