import getEnv from '../../getEnv';
import { TapperEnvironment } from '../../shared/api/types';
import { GuestEmailValidationError } from '../errors/GuestEmailValidationError';
import { GuestPaymentIntentAlreadyUsedError } from '../errors/GuestPaymentIntentAlreadyUsedError';
import { GuestPaymentIntentNotFoundError } from '../errors/GuestPaymentIntentNotFoundError';
import { InvalidGuestPaymentIntentError } from '../errors/InvalidGuestPaymentIntentError';
import { UnableToProcessPaymentError } from '../errors/UnableToProcessPaymentError';
import {
	defaultElementClasses,
	defaultElementStyles,
	defaultPaymentRequestButtonStyle,
} from './constants';
import { GuestCheckoutPaymentIntent } from './types';

interface StripeCheckoutProviderProps {
	stripe: stripe.Stripe;
	singleUseToken: string;
	stripeElementsOptions?: stripe.elements.ElementsCreateOptions;
	tapperEnvironment: TapperEnvironment;
}
class StripeCheckoutProvider {
	private stripe: stripe.Stripe;
	private elements: stripe.elements.Elements;
	private singleUseToken: string;
	private tapperEnvironment: TapperEnvironment;

	constructor({
		stripe,
		singleUseToken,
		stripeElementsOptions = {},
		tapperEnvironment,
	}: StripeCheckoutProviderProps) {
		this.stripe = stripe;
		this.singleUseToken = singleUseToken;
		this.elements = stripe.elements(stripeElementsOptions);
		this.tapperEnvironment = tapperEnvironment;
	}

	createElement(
		type: stripe.elements.elementsType,
		options: stripe.elements.ElementsOptions = {}
	) {
		return this.elements.create(type, {
			style: defaultElementStyles,
			classes: defaultElementClasses,
			...options,
		});
	}

	createPaymentRequestButton(
		paymentRequest: stripe.paymentRequest.StripePaymentRequest,
		paymentRequestButtonStyle: stripe.elements.PaymentRequestButtonStyleOptions = defaultPaymentRequestButtonStyle
	) {
		return this.createElement('paymentRequestButton', {
			paymentRequest,
			style: {
				paymentRequestButton: paymentRequestButtonStyle,
			},
		});
	}

	createPaymentRequest(options: stripe.paymentRequest.StripePaymentRequestOptions) {
		return this.stripe.paymentRequest({
			...options,
			requestPayerEmail: true,
		});
	}

	async getPaymentIntent({
		guestEmail,
		recaptchaToken,
	}: {
		guestEmail?: string;
		recaptchaToken?: string;
	}): Promise<GuestCheckoutPaymentIntent> {
		const response = await fetch(
			`${
				getEnv(this.tapperEnvironment).tapperApiUrl
			}/v1/public/payment/guest_payment/prepare`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					...(guestEmail && { guest_email: guestEmail }),
					single_use_token: this.singleUseToken,
					...(recaptchaToken && {
						recaptcha: {
							g_recaptcha_response: recaptchaToken,
						},
					}),
				}),
			}
		);
		const data = await response.json();

		if (!response.ok) {
			switch (response.status) {
				case 404:
					throw new GuestPaymentIntentNotFoundError();
				case 422:
					throw new GuestEmailValidationError();
				case 409:
					if (data.error.code === 'invalid_guest_payment_intent')
						throw new InvalidGuestPaymentIntentError();
					else if (data.error.code === 'guest_payment_intent_already_used')
						throw new GuestPaymentIntentAlreadyUsedError();
					else throw new UnableToProcessPaymentError();
				default:
					throw new UnableToProcessPaymentError();
			}
		}

		return {
			tabId: data.tab_id,
			clientSecret: data.client_secret,
		};
	}

	async confirmCardPayment(
		clientSecret: string,
		data?: stripe.ConfirmCardPaymentData,
		options?: stripe.ConfirmCardPaymentOptions
	) {
		return await this.stripe.confirmCardPayment(clientSecret, data, options);
	}

	async confirmCardSetup(clientSecret: string, data?: any) {
		return await this.stripe.confirmCardSetup(clientSecret, data);
	}
}

export default StripeCheckoutProvider;
