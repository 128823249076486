import * as Type from './types';

export const defaultMerchantData: Type.MerchantData = {
	id: '',
	first_name: '',
	last_name: '',
	country: '',
	dob: '',
	url: '',
	default_payout_currency: '',
	company_name: '',
	company_tax_id: '',
	company_structure: undefined,
	business_type: undefined,
	ssn_last_4: '',
};

export const defaultUserData: Type.UserData = {
	id: '',
	first_name: '',
	last_name: '',
	created_at: '',
	updated_at: '',
	deleted_at: '',
	email: '',
	active: false,
	default_payment_method_set: false,
	is_email_verified: false,
};

export const defaultError: Type.ErrorObject = {
	message: null,
	field: null,
};

export const companyStructureOptions = {
	sole_proprietorship: 'Sole Proprietorship',
	single_member_llc: 'Single Member LLC',
	private_partnership: 'Private Partnership',
	private_corporation: 'Private Corporation',
	unincorporated_association: 'Unincorporated Association',
	public_partnership: 'Public Partnership',
	public_corporation: 'Public Corporation',
};

export const countriesSupportingBusiness = ['US'];

export enum kycStages {
	EMAIL = 'email',
	BASIC = 'basic',
	BANKING = 'banking',
}
