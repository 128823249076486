import styled from 'styled-components';

export const Container = styled.div`
	text-align: center;
`;

export const Form = styled.form`
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	gap: 12px;
`;

export const Title = styled.h1`
	width: 100%;
	margin: 0 0 60px;
	font-family: Helvetica Neue, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
		Ubuntu, Cantarell, Fira Sans, Droid Sans, sans-serif;
	font-weight: 500;
	font-size: 1.5rem;
	line-height: 1.3;
`;

export const ErrorMessage = styled.p`
	width: 100%;
	margin: -20px 0 20px;
	color: #be0303;
	&[data-position='footer'] {
		margin-top: 10px;
		margin-bottom: 0;
	}
`;

const inputStyles = `
	width: 100%;
	height: 48px;
	margin-bottom: unset;
	padding: 0 15px;
	border: 1px solid #989898;
	border-radius: 4px;
	font-family: Helvetica Neue, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
		Ubuntu, Cantarell, Fira Sans, Droid Sans, sans-serif;
	font-size: 1rem;
	color: #363740;
	&::placeholder {
		color: #989898;
	}
	&:focus,
	&:focus-visible {
		outline: 0;
		border: 1px solid #602576;
	}
`;

export const Select = styled.select`
	${inputStyles}
	width: 100%;
	appearance: none;
	margin-bottom: 0;
	padding: 0 10px;
	&:required:invalid {
		color: #989898;
	}
	option:not(:disabled) {
		color: #363740;
	}
`;

export const FormInput = styled.input`
	${inputStyles}
`;

export const InputGroup = styled.div`
	width: 100%;
	text-align: left;
	&[data-error='true'] {
		${FormInput},
		${Select} {
			border-color: #be0303;
			color: #be0303;
		}
	}
	${ErrorMessage} {
		margin: 4px 0 0;
		line-height: 14px;
		font-size: ${12 / 16}rem;
	}
`;

export const InputLabel = styled.label`
	font-size: ${14 / 16}rem;
	line-height: 28px;
`;

export const SelectWrapper = styled.div`
	position: relative;
	width: 100%;
	text-align: left;
	&:after {
		content: '';
		position: absolute;
		bottom: 20px;
		right: 13px;
		width: 0;
		height: 0;
		border-color: #363740 transparent transparent;
		border-width: 8px 6px 0;
		border-style: solid;
		pointer-events: none;
	}
`;

export const SelectWrapperFlex = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 8px;
	width: 100%;
`;

export const Button = styled.button`
	${inputStyles}
	width: 100%;
	cursor: pointer;
	margin-top: 20px;
	border: 1px solid #363740;
	font-weight: 500;
	font-size: 1rem;
	border: 1px solid transparent;
	border-radius: 100px;
	color: #fff;
	background-color: #602576;
	&[disabled] {
		cursor: not-allowed;
		opacity: 0.65;
	}
`;

export const Paragraph = styled.p`
	max-width: 320px;
	margin: 10px auto;
	font-size: 0.85rem;
	line-height: 1.6;
	color: #363740;
	a {
		color: #363740;
		text-decoration: underline;
	}
`;
