/*
	TODOs:

	? Should we create some kinda axios instance for communicating with SSO
		* See src/shared/api/index.ts
	* Maybe create some error types that can come from SSO registration
*/

import getEnv from '../../getEnv';
import { TapperEnvironment } from '../../shared/api/types';

interface TapperSSOProviderProps {
	email: string;
	clientId: string;
	redirectBack?: string;
	tapperEnvironment?: TapperEnvironment;
}

class TapperSSOProvider {
	email: string;
	clientId: string;
	redirectBack: string;
	tapperEnvironment: TapperEnvironment;

	constructor({
		email,
		clientId,
		redirectBack,
		tapperEnvironment,
	}: TapperSSOProviderProps) {
		this.email = email;
		this.clientId = clientId;
		this.redirectBack = redirectBack;
		this.tapperEnvironment = tapperEnvironment;
	}

	async register(password: string) {
		const response = await fetch(
			`${getEnv(this.tapperEnvironment).contributeToUrl}/user/api/register`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Referer: window.location.origin,
				},
				body: JSON.stringify({
					password,
					email: this.email,
					client_id: this.clientId,
					redirect_back: this.redirectBack,
				}),
			}
		);

		if (!response.ok) {
			const { error } = await response.json();
			throw error;
		}
	}

	getSocialLoginUrls() {
		const nextUrlParam = '?next=/user/social_accounts/';
		const encodedClientIdAndRedirect = encodeURIComponent(
			`?client_id=${this.clientId}&redirect_back=${this.redirectBack}`
		);
		return {
			googleUrl: `${
				getEnv(this.tapperEnvironment).contributeToUrl
			}/social/login/google-oauth2/${nextUrlParam}${encodedClientIdAndRedirect}`,
			appleUrl: `${
				getEnv(this.tapperEnvironment).contributeToUrl
			}/social/login/apple-id/${nextUrlParam}${encodedClientIdAndRedirect}`,
		};
	}
}

export default TapperSSOProvider;
