import { Fragment, h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import * as Type from './types';
import { countriesSupportingBusiness, defaultMerchantData, kycStages } from './utils';
import useLogin from '../shared/hooks/useLogin';

import BasicInfo from './components/BasicInfo';
import BankingInfo from './components/BankingInfo';

import * as S from './styled';
import Error from './components/Error';
import { Spinner } from '../shared/components/Spinner';
import EmailVerification from './components/EmailVerification';

export default function App({
	oauth2Client,
	tapperApiClient,
	onKYCSuccess,
	onSuccessPageButtonClick,
	emailVerificationUrl,
	merchantId,
	successPageTitle,
	successPageInstructions,
	successPageButtonLabel,
}: Type.KYCComponentProps) {
	const { isLoggedIn, userId } = useLogin({
		requireLogin: true,
		oauth2Client,
		tapperApiClient,
	});

	const [showGenericError, setShowGenericError] = useState<boolean>(false);
	const [kycStage, setKycStage] = useState<Type.KYCStage>('');
	const [merchantData, setMerchantData] =
		useState<Type.MerchantData>(defaultMerchantData);
	const [emailVerified, setEmailVerified] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		isLoggedIn && userId && getUserData() && getMerchantData() && setLoading(false);

		async function getMerchantData() {
			Promise.resolve(merchantId ?? tapperApiClient.getMerchantId())
				.then(id => tapperApiClient.getMerchantInfo(id))
				.then(data => {
					if (!data.country) {
						data.country = 'US';
					}
					if (!data.business_type) {
						data.business_type = countriesSupportingBusiness.includes(data.country)
							? 'company'
							: 'individual';
					}
					setMerchantData(data);
				})
				.catch(() => setShowGenericError(true));
		}
		async function getUserData() {
			tapperApiClient
				.getUserInfo(userId)
				.then(data => {
					setEmailVerified(data.is_email_verified);
				})
				.catch(() => setShowGenericError(true));
		}
	}, [isLoggedIn, userId, tapperApiClient, merchantId]);

	useEffect(() => {
		if (loading || !isLoggedIn || !merchantData.id) {
			return;
		}

		if (!emailVerified) {
			setKycStage(kycStages.EMAIL);
		} else if (
			(!merchantData.first_name || !merchantData.last_name) &&
			!merchantData.company_name
		) {
			setKycStage(kycStages.BASIC);
		} else {
			setKycStage(kycStages.BANKING);
		}

		setShowGenericError(false);
	}, [loading, isLoggedIn, merchantData, emailVerified]);

	function onEmailVerificationComplete() {
		setTimeout(() => {
			setEmailVerified(true);
		}, 2000);
	}

	return (
		<S.Container>
			{!loading ? (
				<Fragment>
					{showGenericError && <Error />}
					{kycStage === 'email' && (
						<EmailVerification
							tapperApiClient={tapperApiClient}
							userId={userId}
							onComplete={onEmailVerificationComplete}
							emailVerificationUrl={emailVerificationUrl}
						/>
					)}
					{kycStage === 'basic' && (
						<BasicInfo
							merchant={merchantData}
							tapperApiClient={tapperApiClient}
							onKYCSuccess={onKYCSuccess}
							onSuccessPageButtonClick={onSuccessPageButtonClick}
							successPageButtonLabel={successPageButtonLabel}
							successPageInstructions={successPageInstructions}
							successPageTitle={successPageTitle}
						/>
					)}
					{kycStage === 'banking' && (
						<BankingInfo
							merchant={merchantData}
							tapperApiClient={tapperApiClient}
							onKYCSuccess={onKYCSuccess}
						/>
					)}
				</Fragment>
			) : (
				<Spinner />
			)}
		</S.Container>
	);
}
