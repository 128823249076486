import { h } from 'preact';
import * as S from '../../styled';

type Props = {
	onClick: (e: MouseEvent) => void;
	title?: string;
	instructions?: string;
	buttonLabel?: string;
};

export default function Success({ onClick, title, instructions, buttonLabel }: Props) {
	return (
		<S.Container data-step="success">
			<S.Title style={{ marginBottom: '20px' }}>
				{title ?? (
					<span>
						Congrats!
						<br />
						Your Account Setup Is Done
					</span>
				)}
			</S.Title>
			{instructions && <S.Paragraph>{instructions}</S.Paragraph>}
			{buttonLabel && onClick && <S.Button onClick={onClick}>{buttonLabel}</S.Button>}
		</S.Container>
	);
}
